<template>
    <BT-Dialog
        cardTextClass="mx-0 px-0"
        width="500"
        :block="!iconOnly"
        :buttonClass="buttonClass"
        label="Packages"
        :text="iconOnly ? null : label"
        :icon="iconOnly ? 'mdi-pencil' : 'mdi-cube-unfolded'"
        :getOnOpen="getPackages"
        @ok="save"
        :small="small">
        <template slot-scope="{ item }">
            <v-list dense>
                <template v-for="(m, index) in item">
                    <v-list-item :key="index" dense>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ m.measurementStandard }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ m | toMeasurementLine }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content>
                            <BT-Number-Edit
                                v-if="packed"
                                v-model="m.quantityPacked"
                                label="Packed"
                                :singleLine="false" />

                            <BT-Number-Edit
                                v-if="released"
                                v-model="m.quantityReleased"
                                label="Released"
                                :singleLine="false" />

                            <BT-Number-Edit
                                v-if="delivered"
                                v-model="m.quantityDelivered"
                                label="Delivered"
                                :singleLine="false" />

                            <BT-Number-Edit
                                v-if="received"
                                v-model="m.quantityReceived"
                                label="Received"
                                :singleLine="false" />
                                
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
        </template>
    </BT-Dialog>
</template>

<script>
export default {
    name: 'BT-Packages-Movement-Dialog',
    components: {
        BTDialog: () => import('~components/BT-Dialog.vue')
    },
    data: function() {
        return {
            measurements: [],
            packages: [],
        }
    },
    props: {
        buttonClass: {
            type: String,
            default: null
        },
        iconOnly: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: 'Edit Packages'
        },
        packed: {
            type: Boolean,
            default: false
        },
        released: {
            type: Boolean,
            default: false
        },
        delivered: {
            type: Boolean,
            default: false
        },
        received: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        value: null,
    },
    async mounted() {
        try {
            this.measurements = await this.$BlitzIt.store.getAll('measurements');
        }
        catch {
            console.log('error getting measurements');
        }
    },
    methods: {
        getPackages() {
            //var l = this.copyDeep(this.value);
            var l = this.measurements.map(x => Object.assign({}, x, 
                { 
                    quantityPacked: 0,
                    quantityReleased: 0,
                    quantityDelivered: 0,
                    quantityReceived: 0,
                    measurementID: x.id,
                    measurementStandard: x.measurementStandard,
                    id: null,
                    code: null
                }));

            l.forEach(m => {
                var measurement = this.value.find(y => y.measurementID == m.measurementID || y.measurementStandard == m.measurementStandard);
                if (measurement != null) {
                    m.id = measurement.id;
                    m.quantityPacked = measurement.quantityPacked;
                    m.quantityReleased = measurement.quantityReleased;
                    m.quantityDelivered = measurement.quantityDelivered;
                    m.quantityReceived = measurement.quantityReceived;
                    m.code = measurement.code;
                    m.height = measurement.height;
                    m.width = measurement.width;
                    m.length = measurement.length;
                    m.volume = measurement.volume;
                }
            });

            this.value.forEach(existingP => {
                var ex = l.find(y => y.measurementID == existingP.measurementID || y.measurementStandard == existingP.measurementStandard);
                if (ex == null) {
                    console.log(existingP);
                    l.push(existingP);
                }
            })

            return l;
        },
        save(res) {
            if (this.isLengthyArray(res)) {
                this.$emit('ok', res.filter(y => y.quantityPacked > 0 || y.quantityReleased > 0 || y.quantityDelivered > 0 || y.quantityReceived > 0));
            }
            else {
                this.$emit('ok', res);
            }
        }
    }
}
</script>